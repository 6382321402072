import React from "react";
import { graphql } from "gatsby";
import styled, {ThemeProvider}  from 'styled-components';

import Card from '../components/Card';
import Seo from '../components/Seo';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

import Theme from '../constants/theme';


export default function Template({data}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <ThemeProvider theme={Theme}>
      <PageWrapper>
        <Seo title={frontmatter.title} /> 
        <Nav /> 
        <CardWrapper>
          <ContentCard>
            <Content dangerouslySetInnerHTML={{ __html: html }} />
          </ContentCard>
          <Footer />
        </CardWrapper>
      </PageWrapper>
    </ThemeProvider>
  )
}

const PageWrapper = styled.div`
  background-color: ${({theme}) => theme.color.background};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1600px;
  padding: 150px 30px 40px;
  box-sizing: border-box;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.sm}){
    padding: 150px 10px 40px;  
  }
`

const ContentCard = styled(Card)`
  padding: 30px;
  box-sizing: border-box;
`

const Content = styled.div`

  & h1, h2, h3, h4{
    font-family: futura-pt-bold, sans-serif;
    font-weight: 700;
    font-style: normal;
    color: ${({theme}) => theme.color.text};
  }
  & p, li{
    font-family: baskerville-display-pt, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2rem;
    color: ${({theme}) => theme.color.text};
  }
  & a{
    font-family: baskerville-display-pt, serif;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    color: ${({theme}) => theme.color.text};
    &:hover{
        color: ${({theme}) => theme.color.lightText};
    }
  }

`

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`